<template>
    <el-scrollbar class="createStep" style="height: calc(100vh - 140px);overflow-y: auto">
        <div class="createStepProgress">
            <div class="step-num">
                <span class="active">1</span>
                <span>创建客服数据</span>
            </div>
            <img class="step-wire" :src="active > 1 ? require('./../../../assets/images/serviceData/blue.png') : require('./../../../assets/images/serviceData/xian.png')" alt="">
            <div class="step-num">
                <span :class="active > 1 ? 'active' : ''">2</span>
                <span>添加客服数据</span>
            </div>
            <img class="step-wire" :src="active === 3 ? require('./../../../assets/images/serviceData/blue.png') : require('./../../../assets/images/serviceData/xian.png')" alt="">
            <div class="step-num">
                <span :class="active === 3 ? 'active' : ''">3</span>
                <span>创建成功</span>
            </div>
        </div>
        <div class="createStepForm">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        name: "serviceDataCreationComp",
        data(){
            return {
                active:1,
            }
        },
        watch:{
            $route(route){
                if(route.name == 'createStepOne'){
                    this.active = 1
                } else if(route.name == 'createStepTwo'){
                    this.active = 2
                } else if(route.name == 'createStepSucceed'){
                    this.active = 3
                }
            }
        },
        created() {
            if(this.$route.name == 'createStepOne'){
                this.active = 1
            } else if(this.$route.name == 'createStepTwo'){
                this.active = 2
            } else if(this.$route.name == 'createStepSucceed'){
                this.active = 3
            }
        },
        methods: {
            toBack(){
                if(this.active === 1){
                    this.$router.push({
                        path:'/admin/storecustomer'
                    })
                } else {
                    if(this.active === 2){
                        // this.active--;
                        this.$router.push({
                            path:'/admin/storecustomer/createstepone'
                        })
                    }
                }
            },
            toNext(){{
                if(this.active === 1){
                    // this.active++;
                    this.$router.push({
                        path:'/admin/storecustomer/createsteptwo'
                    })
                } else if (this.active === 2){
                    // this.active++;
                    this.$router.push({
                        path:'/admin/storecustomer/createstepsucceed'
                    })
                }
            }
            }
        }
    }
</script>

<style scoped lang="scss">

    .createStep{
        height: 100%;
        /*padding: 30px;*/
        display: flex;
        flex-direction: column;
        /deep/.el-scrollbar__wrap{
            overflow-x: hidden;
            .el-scrollbar__view{
                padding: 30px;
            }
        }
        .createStepProgress{
            margin: 30px auto;
            padding-bottom: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            .step-num{
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                span:nth-child(1){
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    color: #FFFFFF;
                    background: #DCDCDC;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 48px;
                }
                .active{
                    background: #1122D8!important;
                }
                span:nth-child(2){
                    display: inline-block;
                    width: 100px;
                    text-align: center;
                    top: 68px;
                    /*left: 30%;*/
                    position: absolute;
                    font-size: 16px;
                    color: #333333;

                }
            }
            .step-wire{
                height: 14px;
                margin: 0 10px;
            }
        }
        .createStepBottom{
            margin-top: 100px;
            display: flex;
            justify-content: center;
            .last-step{
                width: 100px;
                background: #ffffff;
                color: #1122D8;
                border-color:#1122D8 ;
            }
            .next-step{
                width: 100px;
                background: #1122D8;
                color: #ffffff;
                border-color: #1122D8;
                margin-left: 20px;
            }
        }
        .createStepForm{
            height: 100%;
        }
    }
</style>