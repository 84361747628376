<template>
    <div class="serviceDataCreation">
        <el-tabs v-model="activeName" type="card" class="education-content tabs-full">
            <el-tab-pane label="客服管理" name="serviceAdminName">
                <serviceDataCreationComp />
            </el-tab-pane>
            <el-tab-pane label="客服商品管理" name="serviceGoodsAdminName">
                <commodityManagement />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import commodityManagement from './../../components/admin/shopServiceAdmin/commodityManagement.vue';
    import serviceDataCreationComp from './../../components/admin/shopServiceAdmin/serviceDataCreationComp.vue';
    export default {
        name: "serviceDataCreation",
        components: {
            commodityManagement,
            serviceDataCreationComp
        },
        data(){
            return {
                activeName:'serviceAdminName',
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-step__description{
        padding-top: 20px;
    }
    /deep/.el-step__head.is-finish{
        /*background: #1122D8;*/
        /*color: #ffffff;*/
        /*border-color: #1122D8;*/
    }
    /deep/.el-step__icon{
        width: 48px;
        height: 48px;
        font-size: 24px;
    }
    /deep/.el-step__line{
        margin-top: 12px;
    }


</style>